export default {
  [`${import.meta.env.VITE_ORANGE_SPECIAL_ROUTE}`]: {
    YPARAMS_SLOTS: ['campaign_slot', 'id_command_slot'],
    WEBCHAT_INIT_PARAMS: `${import.meta.env.VITE_ORANGE_SPECIAL_ROUTE_WEBCHAT_SETTINGS}`
      ? JSON.parse(`${import.meta.env.VITE_ORANGE_SPECIAL_ROUTE_WEBCHAT_SETTINGS}`)
      : {}
  },
  [`${import.meta.env.VITE_ORANGE_RAPPEL_SPECIAL_ROUTE}`]: {
    YPARAMS_SLOTS: ['campaign_slot_n_one', 'id_command_slot_n_one'],
    WEBCHAT_INIT_PARAMS: `${import.meta.env.VITE_ORANGE_RAPPEL_SPECIAL_ROUTE_WEBCHAT_SETTINGS}`
      ? JSON.parse(`${import.meta.env.VITE_ORANGE_RAPPEL_SPECIAL_ROUTE_WEBCHAT_SETTINGS}`)
      : {}
  }
}
